<template>
  <div class="crm">
    <side-bar></side-bar>
    <div class="main" id="crm-main">
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </div>
</template>
<script>
import SideBar from "./nav/SiderNav.vue";
import { mapMutations } from "vuex";
export default {
  components: { SideBar },
  created() {
    this.setMenu(5);
  },
  methods: {
    ...mapMutations("jiyang/menu", ["setMenu"]),
  },
};
</script>
<style lang="scss">
.crm {
  width: 100%;
  height: calc(100vh - 5.2rem);
  background-color: #f6f7fb;
  display: flex;
  .main {
    overflow-y: hidden;
    display: flex;
    flex: 1;
    .customer-view {
      width: 100%;
      overflow: auto;
    }
    .customer {
      height: calc(100vh - 52px);
      flex: 1;
      background-color: #f6f7fb;
      overflow: hidden;
      .card-view {
        .pagination {
          display: flex;
          justify-content: flex-end;
          padding: 10px 100px;
        }
      }
      .customer-cards {
        display: flex;
        padding: 0 100px;
        flex-wrap: wrap;
        row-gap: 16px;
        column-gap: 40px;
        justify-content: flex-start;
        .customer-card {
          margin-bottom: 24px;
        }
      }
      @media screen and (max-width: 1440px) {
        .customer-cards {
          padding: 0 70px;
        }
      }
      .p-100 {
        padding: 0 100px;
      }
      .breadcrumb {
        color: var(--primaryColor);
        line-height: $head-line-height;
        padding-left: 32px;
        font-size: $head-font-size;
        font-weight: 700;
        background-color: #fff;
        border-bottom: 1px solid #d6d6d6;
      }
      .customers {
        padding-top: 15px;
        padding-bottom: 100px;
        height: calc(100vh - 74px - 64px);
      }
      .sell-list {
        position: absolute;
        top: 40px;
        left: 10px;
        z-index: 990;
      }
    }
  }
}
::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}
</style>
