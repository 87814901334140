<template>
  <div
    class="sider-nav"
    v-dragSize="{ min: 200, max: 500 }"
    :style="{ width: navCollapse ? '62px' : '320px' }"
  >
    <div class="nav-collapse-icon" @click="handleClickCollapse">
      <a-icon type="right-circle" v-if="navCollapse" />
      <a-icon type="left-circle" v-else />
    </div>
    <a-collapse v-model="activeKey" :bordered="false" v-show="!navCollapse">
      <template #expandIcon="props">
        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
      </template>
      <a-collapse-panel
        v-for="(panel, idx) in panels"
        :key="String(idx)"
        :header="$t(panel.title)"
        :style="{
          fontSize: '$base-font-size',
          fontWeight: '$head-font-weight',
        }"
      >
        <ul class="mine">
          <li
            class="mine-item"
            :class="active == m.path ? 'active' : null"
            v-for="(m, idx) in panel.menus"
            :key="idx"
            @click="handleClick(m)"
          >
            <i class="circle"></i>
            <span class="label">
              {{ $t(m.label) }}
              <span v-if="m.total && m.total > 0" class="red-circle">
                {{ m.total }}
              </span>
            </span>
          </li>
        </ul>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import { mapState, mapMutations,mapActions } from "vuex";

export default {
  data() {
    return {
      panels: [
        {
          title: "lead",
          menus: [
            {
              path: "/crm/lead?menu=publicLead",
              label: "publicClue",
              total: 0,
            },
            {
              path: "/crm/lead?menu=publicInquiry",
              label: "publicInquiry",
              total: 0,
            },
            {
              path: "/crm/lead?menu=myInquiry",
              label: "myInquiry",
              total: 0,
            },
          ],
        },
        {
          title: "customer",
          menus: [
            {
              path: "/crm/customer?menu=publicCustomer",
              label: "publicCustomer",
            },
            {
              path: "/crm/customer?menu=companyCustomer",
              label: "companyCustomer",
            },
            {
              path: "/crm/customer?menu=myCustomer",
              label: "myCustomer",
            },
          ],
        },
        {
          title: "form",
          menus: [
            {
              path: "/crm/crmLists?menu=5",
              label: "CBD",
              total: 0,
            },
            {
              path: "/crm/recommend?menu=recommend",
              label: "recommend",
              total: 0,
            },
            {
              path: "/crm/crmLists?menu=2",
              label: "quotationForm",
              total: 0,
            },
            {
              path: "/crm/crmLists?menu=3",
              label: "orderForm",
              total: 0,
            },
          ],
        },
		{
			title:"basicLibrary",
			menus:[
				{
					path:'/crm/material?menu=material',
					label:"material",
					total:0
				},
				{
					path:'/crm/supplier?menu=supplier',
					label:"materialSupplier",
					total:0
				}
			]
		}
      ],
      active: "/crm/lead?menu=publicInquiry",
      activeKey: ["0", "1", "2","3"],
      defaultActiveKey: "1",
      publicEnquiryTotal: 0,
	  times:''
    };
  },
  computed: {
    ...mapState("jiyang/enquiry", ["publicEnquiry"]),
    ...mapState("jiyang/count", ["countList"]),
    ...mapState("jiyang/app", ["navCollapse"]),
  },
  created() {
    this.init();
	let that = this;
	that.getCount();
	this.times = setInterval(function(){
		that.getCount();
	},5000)
  },
  destroyed(){
  	  clearInterval(this.times);
  },
  mounted() {
    this.resize();
    window.addEventListener("resize", this.resize);
  },
  watch: {
    publicEnquiry(val) {
      this.publicEnquiryTotal = val.length;
      /* this.panels.splice(0, 1, {
        title: "lead",
        menus: [
          {
            path: "/crm/lead?menu=publicLead",
            label: "publicClue",
            total: 0,
          },
          {
            path: "/crm/lead?menu=publicInquiry",
            label: "publicInquiry",
            total: 0,
          },
          {
            path: "/crm/lead?menu=myInquiry",
            label: "myInquiry",
            total: this.publicEnquiryTotal,
          },
        ],
      }); */
    },
    countList(list) {
      this.panels.some((val, key) => {
        if (val.title === "form") {
          val.menus[2].total = list.enquiry_count;
		  val.menus[3].total = list.order_count;
          this.$set(this.panels, key, val);
          //return true;
        }else if(val.title === "lead"){
		  val.menus[1].total = list.sea_enquiry_count;
		  this.$set(this.panels, key, val);
		}
      });
    },
    $route() {
      this.init();
    },
  },
  methods: {
    ...mapMutations("jiyang/app", ["setCollapse"]),
	...mapActions("jiyang/count", ["getCount"]),
    init() {
      this.setActiveMenu();
    },
    handleClickCollapse() {
      this.setCollapse(!this.navCollapse);
    },
    resize() {
      this.$nextTick(() => {
        let svg = document.querySelector("sider-nav svg");
        svg && (svg.width = "1em");
      });
    },
    setActiveMenu() {
      this.active = this.$route.fullPath.split("&")[0];
    },
    handleClick(m) {
      let [p1, p2] = m.path.split("?");
      const parmas = new URLSearchParams(p2);
      let menu = parmas.get("menu") || this.active;
      this.$router
        .push({
          path: p1,
          query: { menu, t: Date.now() },
        })
        .catch(() => {});
    }
  },
};
</script>

<style lang="scss">
.sider-nav {
  width: $left-side-bar;
  height: calc(100vh - 5.2rem);
  flex-shrink: 0;
  background-color: #fbfbfb;
  border-right: 1px solid #d6d6d6;
  position: relative;
  transition: width 0.4s ease-in;
  overflow-y: auto;
  overflow-x: clip;

  .nav-collapse-icon {
    cursor: pointer;
    background-color: #fff;
    top: 14px;
    right: 0px;
    position: absolute;
    font-size: 30px;
    z-index: 3;
    > i > svg {
      path:nth-child(2) {
        color: #ddd;
      }
    }
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-weight: 500;
  }
  .ant-collapse-borderless {
    background-color: #fbfbfb;
    border: 0;
  }
  .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: 1px solid transparent;
  }
  .mine {
    margin-left: 20px;
    margin-top: 10px;
    .mine-item {
      color: #000;
      display: flex;
      align-items: center;
      margin-bottom: 28px;
      font-size: $base-font-size;
      font-weight: 500;
      position: relative;
      user-select: none;
      &:hover {
        cursor: pointer;
        color: var(--primaryColor);
        .circle {
          background-color: var(--primaryColor);
        }
      }
      .label {
        position: relative;
      }
      .red-circle {
        min-width: 16px;
        height: 16px;
        padding: 0 4px;
        border-radius: 8px;
        background-color: #fa4444;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -10px;
        right: -20px;
        color: #fff;
        font-size: 10px;
        z-index: 3;
      }
      &.active {
        color: #2fc2a9;
        .circle {
          background-color: #2fc2a9;
        }
      }
    }
    .circle {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #959595;
      margin-right: 6px;
    }
  }
}
</style>
